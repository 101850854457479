<template>
  <div>
    <vue-video-section
      :elementId="'header-background-video'"
      :ref="'header-background-video'"
      :mp4Source="require('../../assets/vid.mp4')"
      :posterSource="require('../../assets/blue-skyscraper.jpg')"
      :mobileBreakpoint="992"
      :desktopHeight="windowHeight"
      :mobileHeight="windowHeight"
      :playsinline="true"
      :loop="true"
      :autoplay="true"
      :autobuffer="true"
      :muted="true"
    >
      <div slot="overlay-content" class="overlay-content">
        <div class="container py-4">
          <div class="row">
            <div class="col-lg-12">
              <v-toolbar-items>
                <div v-touch-card="30" class="card">
                  <img
                    width="250px"
                    class=""
                    src="../../assets/image-removebg-preview (5).png"
                  />
                </div>
                <v-spacer></v-spacer>

                <v-btn @click="toggleDrawer" class="hidden-md-and-up" icon>
                  <v-icon color="white">mdi-menu</v-icon>
                </v-btn>

                <v-btn
                  class="hidden-sm-and-down pt-1"
                  active-class="aa subtitle-1"
                  to="/"
                  text
                >
                  <b>{{ $t("home.hero.home") }}</b>
                </v-btn>
                <v-btn
                  class="hidden-sm-and-down pt-1"
                  color="white"
                  to="/about"
                  text
                >
                  {{ $t("home.hero.aboutus") }}
                </v-btn>
                <!-- <v-menu offset-y >
                  <template v-slot:activator="{ attrs, on }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      class="hidden-sm-and-down"
                      color="white"
                      @click="3"
                      text
                    >
                      <b>{{ $t("home.hero.projects") }}</b>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item link>
                      <v-list-item-title>Engineering Design</v-list-item-title>
                    </v-list-item>
                    <v-list-item link>
                      <v-list-item-title>infrastructure</v-list-item-title>
                    </v-list-item>
                    <v-list-item link>
                      <v-list-item-title>strategic</v-list-item-title>
                    </v-list-item>
                    <v-list-item link>
                      <v-list-item-title
                        >Commercial Buildings</v-list-item-title
                      >
                    </v-list-item>
                    <v-list-item link>
                      <v-list-item-title>Residential</v-list-item-title>
                    </v-list-item>
                    <v-list-item link>
                      <v-list-item-title>Water Station</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu> -->
                <v-btn
                  class="hidden-sm-and-down pt-1"
                  color="white"
                  to="/projects"
                  text
                >
                  {{ $t("home.hero.projects") }}
                </v-btn>
                <v-btn
                  class="hidden-sm-and-down pt-1"
                  color="white"
                  to="/contact"
                  text
                >
                  {{ $t("home.hero.contactus") }}
                </v-btn>
                <v-menu offset-y>
                  <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on">
                      <v-icon color="white">mdi-web</v-icon>
                    </v-btn>
                  </template>
                  <v-list v-model="$i18n.locale">
                    <v-list-item
                      :disabled="$i18n.locale === 'en'"
                      @click="changLang()"
                    >
                      <v-list-item-title>English</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      :disabled="$i18n.locale === 'ar'"
                      @click="changLang()"
                    >
                      <v-list-item-title>عربي</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <v-btn @click="setTheme" icon>
                  <v-icon color="white">mdi-invert-colors</v-icon>
                </v-btn>
              </v-toolbar-items>
            </div>
          </div>
        </div>
        <v-container fill-height>
          <v-sheet
            color="transparent"
            class="text-style mt-16 pt-16"
            style="margin-top: 150px"
          >
            <!-- <h1
              style="font-size: 3rem"
              class="white--text"
              v-html="$t('home.hero.title')"
            /> -->

            <v-divider></v-divider>
            <!-- <v-btn
              style="margin-top: 25px"
              large
              outlined
              color="primary"
              class="mt-16 ml-2"
              to="/projects"
            >
              <h3 class="white--text">{{ $t("home.hero.btnEnter") }}</h3>
            </v-btn> -->
          </v-sheet>
        </v-container>
      </div>
    </vue-video-section>
  </div>
</template>

<script>
import Vue from "vue";
import VideoBg from "vue-videobg";
import { mapMutations } from "vuex";

Vue.component("video-bg", VideoBg);
export default {
  name: "Hero",
  data: () => ({
    windowHeight: window.innerHeight,
    txt: "",
  }),
  watch: {
    windowHeight(newHeight, oldHeight) {
      this.txt = `it changed to ${newHeight} from ${oldHeight}`;
    },
  },

  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },

  computed: {
    font: {
      get() {
        return this.$store.state.font;
      },
      set(value) {
        this.$store.commit("setFont", value);
      },
    },
  },
  methods: {
    ...mapMutations(["toggleDrawer"]),
    changLang() {
      if (this.$i18n.locale === "en") {
        this.$i18n.locale = "ar";
        this.$vuetify.rtl = true;
        this.font = "Noto Naskh Arabic";
      } else {
        this.$i18n.locale = "en";
        this.$vuetify.rtl = false;
        this.font = "Montserrat";
      }
    },
    goToWeb() {
      window.open("https://fdallaliq.tk/#/");
    },
    setTheme() {
      if (this.$vuetify.theme.dark) {
        return (this.$vuetify.theme.dark = false);
      } else {
        return (this.$vuetify.theme.dark = true);
      }
    },
    onResize() {
      this.windowHeight = window.innerHeight;
    },
  },
};
</script>

<style lang="scss" scoped>
.aa {
  color: #fff !important;
}
.vue-video-section-wrapper {
  color: rgb(255, 255, 255);
  z-index: 1;
}

.vue-video-section {
  video[poster] {
  }

  &__video-element {
  }

  &__overlay-content-wrapper {
    &__background {
    }

    &__content-wrapper {
      &__content {
      }
    }
  }
}
</style>
